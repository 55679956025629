import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';

function Article({ data }) {
  const { blogShortDescription, title, slug, blogAuthor } = data;

  const articleDate = new Date(data.postDate).toLocaleDateString("en-AU", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
  const heroImage =
    data.heroImage.length > 0 ? (
      <img src={data.heroImage[0].url} className="img-fluid" alt="Your image" />
    ) : (
      <></>
    );

  return (
    <Fade>
    <div className="block-article col-12 col-md-6 my-4 spacer">
      <Link to={`/articles/${slug}`} className="text-decoration-none">
        {heroImage}
      </Link>
      <Link to={`/articles/${slug}`} className="text-decoration-none">
        <h3 className="mt-2 mb-4 generic-padding-top">{title}</h3>
      </Link>
      <div className="mid-lineheight">
        <b>{articleDate}</b>
        {blogAuthor.length > 0 && (
          <>
            <div className="mb-2">
              <b>Written by {blogAuthor[0].title}</b>
              <br />
              <i>{blogAuthor[0].position}</i>
            </div>
          </>
        )}
        <div className="generic-padding-top generic-padding-bottom">
          {blogShortDescription}
        </div>
        <Link to={`/articles/${slug}`} className="text-decoration-none">
          Read more &gt;
        </Link>
      </div>
    </div>
    </Fade>
  );
}

export default Article;
