import Fade from 'react-reveal/Fade';

function TwoColumnWithImageGallery({ data }) {
  const {
    leftHeader,
    cssClassLeftBlock,
    leftText,
    rightHeader,
    cssClassRightBlock,
    rightText,
    images,
  } = data;
  return (
    <Fade>
    <div className="gray-bg container generic-padding-top generic-padding-bottom mb-5">
      <div className="">
        <div className="row generic-padding-bottom">
          <div className="col-md-6 p-4">
            <h2 className={`${cssClassLeftBlock ? cssClassLeftBlock : ""}`}>
              {data.leftHeader}
            </h2>
            <div
              className={`${cssClassLeftBlock ? cssClassLeftBlock : "generic-padding-top"}`}
              dangerouslySetInnerHTML={{ __html: leftText }}
            />
          </div>
          <div className="col-md-6 p-4">
            <h2 className={`${cssClassRightBlock ? cssClassRightBlock : ""}`}>
              {rightHeader}
            </h2>
            <div
              className={`${cssClassRightBlock ? cssClassRightBlock : "generic-padding-top"}`}
              dangerouslySetInnerHTML={{ __html: rightText }}
            />
          </div>
        </div>
      </div>
      <div className="block--image-gallery">
      <div className="container generic-padding-bottom">
        {images &&
          images.map((el, key) => {
            return (
              <img
                src={el.image[0].url}
                className={`p-1 ${el.cssClass}`}
                alt=""
                key={key}
              />
            );
          })}
      </div>
    </div>
    </div>
    </Fade>
  );
}

export default TwoColumnWithImageGallery;
