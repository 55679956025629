import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';


function ProjectTile({ data }) {
  const { slug, imageUrl, title, subTitle } = data;

  return (
    
    <div className="my-3 col-sm-6 block--project-tile generic-padding-top">
      <Fade>
      <Link to={`/projects/${slug}`} className="text-decoration-none">
        <div className="card">
          <img src={imageUrl} alt="" />
          <a className="btn-arrow">
            <p className="mt-2 mb-0 mt-4">{title}</p>
            <p className="text-muted small">{subTitle}</p>
          </a>
        </div>
      </Link>
      </Fade>
    </div>
    
  );
}

export default ProjectTile;
