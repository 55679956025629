import Fade from 'react-reveal/Fade';

function HeroImage({ data }) {
  const { url, onGrayBackground } = data;
  return (
    <Fade>
    <div
      className={`block--hero-image ${!onGrayBackground ? "container" : ""}`}
    >
      <div className={`image-fluid ${onGrayBackground ? "gray-bg" : ""}`}>
        <div className={`${onGrayBackground ? "container" : ""}`}>
            <div className="col-12">
              <img src={url} className="img-fluid" alt="Your image" />
            </div>
        </div>
      </div>
    </div>
    </Fade>
  );
}

export default HeroImage;
