import Fade from 'react-reveal/Fade';

function HeaderAndTextWithBackground({ data }) {
  const { header, cssClassHeader, text, cssClassText, textbackgroundcolor } = data;
  const backgroundImage =
    data.backgroundImage && data.backgroundImage.length > 0
      ? `url(${data.backgroundImage[0].url})`
      : ``;
  const bgStyle = backgroundImage
    ? {
        backgroundImage: backgroundImage,
        backgroundSize: backgroundImage ? "cover" : "none",
        backgroundRepeat: backgroundImage ? "no-repeat" : "none",
        backgroundPosition: "center",
        padding: "3%",
      }
    : {};

  return (
    <Fade>
    <div className="container">
      <div>
        <div className="row p-4">
          <div className="col-12 col-lg-6 spacer fcol" style={bgStyle}>
          </div>
          <div
            className={`col-12 col-lg-6 p-5 spacer generic-lineheight  ${
              cssClassText ? cssClassText : "" 
            }`}
            style={{
              backgroundColor: textbackgroundcolor ? textbackgroundcolor : "#999999",
            }}
          >
            <h2 className={`${cssClassHeader ? cssClassHeader : ""}`}>
              {header}
            </h2>
            <div className="pt-4" dangerouslySetInnerHTML={{ __html: text }}/>
          </div>
        </div>
      </div>
    </div>
    </Fade>
  );
}

export default HeaderAndTextWithBackground;
