function ListOfContributors({ data }) {
  return (
    <>
      <h5 className="text-center">Our team of contributors</h5>
      {data &&
        data.map((el, key) => {
          return (
            <div key={key} className="text-center my-4 generic-padding-top generic-padding-bottom">
              {el.staffPhoto.length > 0 && (
                <>
                  <img
                    src={el.staffPhoto[0].url}
                    alt=""
                    className="img-thumbnail"
                  />
                </>
              )}
              <br />
              <div className="generic-padding-top generic-padding-bottom">
              <b>{el.staffName}</b>
              <br />
              {el.position}
              </div>
            </div>
          );
        })}
    </>
  );
}

export default ListOfContributors;
