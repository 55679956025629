import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import appSettings from "./AppSettings";
import Homepage from "pages/Homepage";
import OurService from "pages/OurService";
import OurTeam from "pages/OurTeam";
import GetInTouch from "pages/GetInTouch";
import AboutUs from "pages/AboutUs";
import OurLatestProjects from "pages/OurLatestProjects";
import LatestArticles from "pages/LatestArticles";
import Article from "pages/Article";
import PrivacyPolicy from "pages/PrivacyPolicy";

import OurProject from "./pages/OurProject";
import MenuNavigation from "./components/MenuNavigation";

export default function App() {
  const { pathname } = useLocation();
  console.log("React.version: "+React.version);
  console.log("appSettings: ",appSettings);
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }
    });

  return (
    <>
      <MenuNavigation />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/our-team" element={<OurTeam />} />
        <Route path="/get-in-touch" element={<GetInTouch />} />
        <Route path="/latest-articles" element={<LatestArticles />} />
        <Route exact path="/services/:service" element={<OurService />} />
        <Route exact path="/projects/:project" element={<OurProject />} />
        <Route exact path="/articles/:article" element={<Article />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/our-latest-projects" element={<OurLatestProjects />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </>
  );
}
