import Fade from 'react-reveal/Fade';

function HeaderAndText({ data }) {
  const { header, cssClassHeader, onGrayBackground, text, cssClassText } = data;
  const backgroundImage =
    data.backgroundImage && data.backgroundImage.length > 0
      ? `url(${data.backgroundImage[0].url})`
      : ``;
      const bgStyle = backgroundImage
      ? {
          backgroundImage: backgroundImage,
          backgroundSize: backgroundImage ? "contain" : "none",
          backgroundRepeat: backgroundImage ? "no-repeat" : "none",
          backgroundPosition: backgroundImage ? 'center' : 'initial',
          minHeight: backgroundImage ? "400px !important" : "auto",
          
          // Media query for 600px screen width
          '@media (min-width: 600px)': {
            height: backgroundImage ? "70vh !important" : "auto",
          },
        }
      : {};
    

  return (
    <Fade>
    <div
      className={`block--header-and-text${
        !onGrayBackground ? " container mt-3 pb-4" : " gray-bg  pt-4 pb-4"
      }
      `}
    >
      <div
        className={`${onGrayBackground ? "gray-section container mid-lineheight" : ""}${
          backgroundImage ? " headerandtext" : ""
        }`}
      >
        <div className="row" >
          <div className="col-12 col-lg-6 p-4" style={bgStyle}>
            <h2 className={`${cssClassHeader ? cssClassHeader : ""}`}>
              {header}
            </h2>
          </div>
          
          <div
            className={`col-12 col-lg-6 p-4 ${
              cssClassText ? cssClassText : ""
            }`}
          >
            <div dangerouslySetInnerHTML={{ __html: text }}/>
          </div>
        </div>
      </div>
    </div>
    </Fade>
  );
}

export default HeaderAndText;
