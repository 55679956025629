import Slide from 'react-reveal/Slide';

function ImageGallery({ data }) {
  const { images } = data;
  return (
    <Slide bottom>
    <div className="block--image-gallery">
      <div className="container generic-padding-bottom">
        {images &&
          images.map((el, key) => {
            return (
              <img
                src={el.image[0].url}
                className={`p-1 ${el.cssClass}`}
                alt=""
                key={key}
              />
            );
          })}
      </div>
    </div>
    </Slide>
  );
}

export default ImageGallery;
