function Navbar({ data }) {
  return (
    <div className="collapse navbar-collapse" id="navbarCollapse">
      <div className="navbar-nav ms-auto">
        {data.map((item, index) => {
          if (item.children.length > 0) {
            return (
              <div className="nav-item dropdown px-3" key={index}>
                <a
                  href={item.url}
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  {item.title}
                </a>
                <div className="dropdown-menu">
                  {item.children.map((subitem, subindex) => (
                    <a
                      href={subitem.url}
                      className="dropdown-item py-3"
                      key={subindex}
                    >
                      {subitem.title}
                    </a>
                  ))}
                </div>
              </div>
            );
          } else {
            return (
              <div className="nav-item dropdown px-3" key={index}>
                <a
                  href={item.url}
                  className={`nav-item nav-link ${item.classes} ${
                    item.active ? "active" : ""
                  }`}
                  key={index}
                  tabIndex={item.disabled ? "-1" : "0"}
                >
                  {item.title}
                </a>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

export default Navbar;
