import Fade from 'react-reveal/Fade';

function Sources({ data }) {
  const { header, text } = data;

  return (
    <Fade>
    <div className="container mt-5 mb-5">
      <div className='container'>
        <div className="row">
          <div className="col-12" >
            <h6 dangerouslySetInnerHTML={{ __html: header }}/>
            <p dangerouslySetInnerHTML={{ __html: text }}/>
          </div>
        </div>
      </div>
    </div>
    </Fade>
  );
}

export default Sources;
