import { useEffect, useState } from "react";
import GraphQlHandler from "api/craft/GraphQlHandler";
import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';
import Footer from "components/Footer";
import Article from "../components/Article";
import ListOfContributors from "../components/ListOfContributors";
import { Link } from "react-router-dom";

function LatestArticles() {
  const [data, setData] = useState({});

  const ARTICLES_PER_PAGE = 4;

  const [pagination, setPagination] = useState({
    current: 0,
    prev: -1,
    next: -1,
    total: 0,
  });
  const goToPreviousPage = () => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("page", pagination.prev);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.location.href = newUrl;
  };

  const goToNextPage = () => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("page", pagination.next);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.location.href = newUrl;
  };

  useEffect(() => {
    GraphQlHandler.query(
      `
        query MyQuery {
          entries(section: "blog") {
            id 
          }
        }
    `
    ).then((res) => {
      const urlParams = new URLSearchParams(window.location.search);
      const thisPage = urlParams.get("page") ? urlParams.get("page") : 1;
      const aTotal = Math.ceil(res.entries.length / ARTICLES_PER_PAGE);
      setPagination({
        ...pagination,
        total: aTotal,
        current: thisPage,
        prev: thisPage > 1 ? parseInt(thisPage) - 1 : -1,
        next: thisPage < aTotal ? parseInt(thisPage) + 1 : -1,
      });
    });
  }, []);

  useEffect(() => {
    if (pagination.current == 0) return;
    GraphQlHandler.query(
      `
        query MyQuery {
          entries(section: "blog") {
            id 
          }
          latestArticlesEntries {
            ... on latestArticles_latestArticles_Entry {
              id
              title
              ourTeamOfContributors {
                ... on staff_staffentry_Entry {
                  id
                  staffName
                  position
                  staffPhoto {
                    url
                  }
                }
              }
              mainContent
            }
          }
          blogEntries(limit: ${ARTICLES_PER_PAGE}, offset: ${
        (pagination.current - 1) * ARTICLES_PER_PAGE
      }) {
            ... on blog_blogentry_Entry {
              postDate
              title
              slug
              blogAuthor {
                title
                ... on staff_staffentry_Entry {
                  id
                  position
                }
              }
              blogShortDescription
              blogFullDescription
              heroImage {
                url
              }
            }
          }
        }
    `
    ).then((res) => {
      const r = res.latestArticlesEntries[0];
      setData({
        ...data,
        title: r.title,
        mainContent: r.mainContent,
        blog: res.blogEntries,
        ourTeamOfContributors: r.ourTeamOfContributors,
      });
    });
  }, [pagination.current]);

  return (
    <>
      <div className="top-gradient"></div>
      <div className="container page--latest-articles generic-padding-top generic-padding-bottom">
        <div className="row mb-4">
          <h1 className="mt-4 subheads">{data.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: data.mainContent }} />
       
          <div className="col-12 col-md-9 row border-right">
            {pagination.total > 0 &&
              pagination.current > 0 &&
              data.blog &&
              data.blog.map((el, key) => {
                return <Article data={el} key={key} />;
              })}
          </div>
          <hr className="h-horizontal"/>
          <div className="col-12 col-md-3">
            <ListOfContributors data={data.ourTeamOfContributors} />
          </div>
        </div>
        <div>
          {pagination.prev > 0 && (
            <button
              className="my-4 btn btn-primary text-align m-4"
              onClick={goToPreviousPage}
            >
              &lt;&lt; Newer Entries
            </button>
          )}
          {pagination.next > 0 && (
            <button
              className="my-4 btn btn-primary text-align"
              onClick={goToNextPage}
            >
              Older Entries &gt;&gt;
            </button>
          )}
        </div>
      </div>
      <Footer entry="projectentry" slug="" />
    </>
  );
}

export default LatestArticles;
