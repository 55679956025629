import { gql, GraphQLClient } from "graphql-request";
import appSettings from "../../AppSettings";

const graphQLClient = new GraphQLClient(appSettings.graphQlEndpoint, {
  headers: {},
});

const GraphQlHandler = {
  async query(queryText) {
    try {
      const data = await graphQLClient.request(
        gql`
          ${queryText}
        `
      );
      console.debug("GraphQl request completed", { queryText, data });
      return data;
    } catch (err) {
      console.error("GraphQl - Failed to make graphQL request", { queryText });
      console.error(err);
      return null;
    }
  },
};

export default GraphQlHandler;

