import Fade from 'react-reveal/Fade';

function Outcomes({ data }) {
  const { results } = data;
  return (
    <Fade>
    <div className="block--outcomes container generic-padding-top gray-bg">
      <div className="row page--home">
        {results &&
          results.map((el, key) => {
            return (
              <div className={`text-center ` + el.cssClass} key={key}>
                <div className="stats-numbers">{el.result}</div>
                <div className="text-muted">{el.textBelow}</div>
              </div>
            );
          })}
      </div>
    </div>
    </Fade>
  );
}

export default Outcomes;
